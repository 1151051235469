body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 992px) {
  #nav-btn {
    display: none;
  }

  .pdfViewer {
    width: 55%;
  }
}

#mainContainer {
  max-width: 100%;
  height: 100%;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}

.nav-link {
  transition: transform 0.3s ease;
}

.nav-link:hover {
  cursor: pointer;
  transform: scale(1.2);
  font-weight: bold;
}

.nav-link.active {
  transform: scale(1.2);
  font-weight: bold;
}

.remember-header {
  font-size: 26px;
}

.shadow-2b {
  text-shadow: black 2px 2px !important;
}

#fb-profile-link {
  border-color: #316FF6 !important;
}

@media (max-width: 600px) {
  #fb-profile-link {
    max-width: 100%;
  }
}

.bg-halloween {
  background-color: #F39F5A;
}

.bg-halloween-accent {
  background-color: #451952;
}